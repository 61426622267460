
























































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthService from '@/common/services/auth-service';
import displayMessage from '@/common/functions/display-message';
import InputGroup from '@/common/components/input-group/input-group.vue';
import LoginStore from '@/store/modules/login';

type KeyOptionUsername = {
  keyNumber: number | null;
  type: string;
};

type AddressUsername = {
  street: string;
  houseNumber: number | null;
  zipCode: number | null;
};

@Component({
  name: 'LoginForm',
  components: {
    InputGroup,
  },
})
export default class LoginForm extends Vue {
  @Ref('loginform')
  loginform!: HTMLFormElement;

  LoginStore = getModule(LoginStore, this.$store);

  authService!: AuthService;

  username = '';

  password = '';

  isLoading = false;

  type = '';

  keyUsername = '';

  addressUsername: AddressUsername = {
    street: '',
    houseNumber: null,
    zipCode: null,
  };

  keyOptionUsername: KeyOptionUsername = {
    keyNumber: null,
    type: '',
  };

  OPTIONS = [
    {
      text: 'Jugendamt',
      value: 'empty_string',
    },
    {
      text: 'Jugendamt Brückenprojekt',
      value: 'b',
    },
  ];

  created(): void {
    this.authService = new AuthService();
    this.type = this.$route.params.type;
  }

  setKeyUsername(userdata: string) {
    this.keyUsername = userdata;
  }

  setUsername() {
    if (this.type === 'admin') {
      return this.username;
    }
    if (this.type === '1' || this.type === '2') {
      return `${this.keyUsername}${this.keyOptionUsername.type}`;
    }
    if (this.type === '3') {
      const selectedType = this.keyOptionUsername.type.replace('empty_string', '');
      return `jugendamt${this.keyOptionUsername.keyNumber}${selectedType}`;
    }
    if (this.type === '4') {
      return this.getProcessedAddress();
    }
    return '';
  }

  async submitLogin() {
    this.username = this.setUsername();
    if (this.username !== '') {
      this.isLoading = true;
      try {
        const loginParams = {
          username: this.username.toLowerCase(),
          password: this.password,
        };
        const { data, isError, message } = await this.authService.login(loginParams);

        if (!isError && data) {
          this.LoginStore.userStatusChangeLoggedIn();
          if (data.is_admin && (data.dummy_email || data.dummy_password)) {
            this.LoginStore.userStatusChangeAdmin();
            this.$router.push('/default-credentials');
          }

          if (data.is_admin && !data.dummy_email && !data.dummy_password) {
            this.LoginStore.userStatusChangeAdmin();
            this.$router.push('/users');
          }

          if (!data.is_admin && (data.dummy_email || data.dummy_password)) {
            this.$router.push('/default-credentials');
          }

          this.$router.push('/profile');
        } else {
          displayMessage(message, true, this.$store);
        }
      } catch (error) {
        displayMessage('An error has occured', true, this.$store);
      }
      this.username = '';
      this.password = '';
      this.keyUsername = '';
      this.addressUsername = {
        street: '',
        houseNumber: null,
        zipCode: null,
      };
      this.keyOptionUsername = {
        keyNumber: null,
        type: '',
      };
      this.isLoading = false;
      this.loginform.reset();
    }
  }

  getProcessedAddress() {
    const { street, houseNumber, zipCode } = this.addressUsername;
    let username = `${street}${houseNumber}${zipCode}`;
    const replacements = [
      { rule: ' ', newValue: '' },
      { rule: 'ß', newValue: 'ss' },
      { rule: 'strasse', newValue: 'str' },
    ];
    username = username.toLowerCase();
    replacements.forEach((replaceObject) => {
      username = username.replaceAll(replaceObject.rule, replaceObject.newValue);
    });
    return username;
  }

  // eslint-disable-next-line class-methods-use-this
  limit(
    event: { preventDefault: () => void; key: string },
    dataProp: number | unknown[] | null,
    limit: number
  ) {
    const preventDefaultRegex = /\d/i;
    if (preventDefaultRegex.test(event.key) === false && event.key !== 'Backspace') {
      event.preventDefault();
      return;
    }
    if (dataProp && event.key !== 'Backspace') {
      if (dataProp.toString().length >= limit) {
        event.preventDefault();
      }
    }
  }
}
