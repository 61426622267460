import { AxiosError } from 'axios';

import { setDummyPass, setDummyEmail, setLogedUserInfo, setToken } from '../functions/auth';
import { LoginParams, Auth } from '../models/user';
import ApiService from './api-service';

export interface Message {
  message: string;
  isError: boolean;
  data?: Auth;
}

export default class AuthService extends ApiService {
  token = '';

  async login(data: LoginParams): Promise<Message> {
    try {
      const resp = await this.axios.post<Auth>('/auth', data);
      if (resp.data.token) {
        setToken(resp.data.token);
        setLogedUserInfo(resp.data.user_id, resp.data.is_admin);
        this.saveOtherLoginInfo(resp.data.dummy_email, resp.data.dummy_password);
        super.setConfigHeaders();
      }
      return { isError: false, message: 'success', data: resp.data };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data?.non_field_errors) {
        return { isError: true, message: e.response.data.non_field_errors[0] };
      }
      return { isError: true, message: 'Internal Error' };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async saveOtherLoginInfo(hasDummyEmail: boolean, hasDummyPass: boolean) {
    if (hasDummyEmail) {
      setDummyEmail(hasDummyEmail);
    } else {
      setDummyEmail(false);
    }

    if (hasDummyPass) {
      setDummyPass(hasDummyPass);
    } else {
      setDummyPass(false);
    }
  }

  async forgotPassword(email: string): Promise<Message> {
    try {
      await this.axios.post('/auth/password_reset/', { email });
      return {
        message: 'Bitte überprüfen Sie Ihre E-Mail, um Ihr Passwort zurückzusetzen',
        isError: false,
      };
    } catch (error) {
      return {
        message: 'Email wurde nicht gefunden',
        isError: true,
      };
    }
  }

  async forgotPasswordConfirmation(token: string, password: string): Promise<Message> {
    try {
      await this.axios.post('/auth/password_reset/confirm/', { token, password });
      return {
        message: 'Passwort erfolgreich aktualisiert',
        isError: false,
      };
    } catch (error) {
      const e = error as AxiosError;

      let errorMessge = 'Fehler beim Aktualisieren des Passworts';

      if (e.response?.data?.password?.length > 0) {
        errorMessge = e.response?.data?.password[0]?.detail;
      }

      return {
        message: errorMessge,
        isError: true,
      };
    }
  }
}
