






























import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

interface InputGroupState {
  counter: number;
  className: string;
  value: number | null;
  separator?: string;
}

const TYPE_1 = [
  {
    counter: 2,
    className: 'input-small',
    value: null,
    separator: '.',
  },
  {
    counter: 2,
    className: 'input-small',
    value: null,
    separator: '-',
  },
  {
    counter: 3,
    className: 'input-large',
    value: null,
    separator: '-',
  },
  {
    counter: 2,
    className: 'input-small',
    value: null,
    separator: '-',
  },
  {
    counter: 4,
    className: 'input-large',
    value: null,
    separator: '.',
  },
  {
    counter: 1,
    className: 'input-small',
    value: null,
  },
];
const TYPE_2 = [
  {
    counter: 2,
    className: 'input-small',
    value: null,
    separator: '.',
  },
  {
    counter: 2,
    className: 'input-small',
    value: null,
    separator: ' ',
  },
  {
    counter: 3,
    className: 'input-large',
    value: null,
    separator: '.',
  },
  {
    counter: 3,
    className: 'input-large',
    value: null,
    separator: '/',
  },
  {
    counter: 1,
    className: 'input-small',
    value: null,
  },
];
@Component
export default class InputGroup extends Vue {
  @Prop()
  inputType!: number;

  @Ref() readonly number0!: [HTMLInputElement];

  @Ref() readonly number1!: [HTMLInputElement];

  @Ref() readonly number2!: [HTMLInputElement];

  @Ref() readonly number3!: [HTMLInputElement];

  @Ref() readonly number4!: [HTMLInputElement];

  @Ref() readonly number5!: [HTMLInputElement];

  inputGroups: InputGroupState[] = [];

  changeUsernameHandler(currentIdx: number) {
    this.emitUsername();
    this.selectInputs(currentIdx);
  }

  emitUsername() {
    let concat = '';
    this.inputGroups.forEach((input) => {
      concat = `${concat}${input.value || ''}`;
    });
    this.$emit('set-username', concat);
  }

  selectInputs(currentIdx: number) {
    const { value, counter } = this.inputGroups[currentIdx];
    const strVal = value ? `${value}` : '';
    if (strVal.length === counter) {
      this.setFocus(currentIdx + 1);
    }
  }

  setFocus(currentIdx: number): void {
    if (currentIdx === 0) {
      this.number0[0].focus();
      return;
    }
    if (currentIdx === 1) {
      this.number1[0].focus();
      return;
    }
    if (currentIdx === 2) {
      this.number2[0].focus();
      return;
    }
    if (currentIdx === 3) {
      this.number3[0].focus();
      return;
    }
    if (currentIdx === 4) {
      this.number4[0].focus();
      return;
    }
    if (currentIdx === 5 && this.inputGroups.length > 5) {
      this.number5[0].focus();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  limit(
    event: { preventDefault: () => void; key: string },
    dataProp: string | unknown[] | null,
    limit: number
  ) {
    const preventDefaultRegex = /\d/i;
    if (preventDefaultRegex.test(event.key) === false && event.key !== 'Backspace') {
      event.preventDefault();
      return;
    }

    if (dataProp && event.key !== 'Backspace') {
      if (dataProp.length >= limit) {
        event.preventDefault();
      }
    }
  }

  created() {
    if (this.inputType === 1) {
      this.inputGroups = TYPE_1;
    } else {
      this.inputGroups = TYPE_2;
    }
  }
}
